
import { FactcheckSuggestion } from "@/types";
import { Component, Prop, Vue } from "vue-property-decorator";

@Component
export default class RequestsStats extends Vue {

    @Prop() requests!: FactcheckSuggestion[];

    get series() {
        return [
            {
                name: 'Aantal',
                data: [
                    this.requests.length,
                    this.requests.filter(r => r.answered).length,
                    this.requests.filter(r => r.answered && r.passed).length,
                    this.requests.filter(r => r.FactcheckId).length,
                ],
            },
        ]
    }

    get chartOptions() {
        return {
            chart: {
                type: 'bar',
                height: 350,
            },
            dataLabels: {
                enabled: true,
                formatter: function (val: any, opt: any) {
                    return opt.w.globals.labels[opt.dataPointIndex] + ':  ' + val
                },
                dropShadow: {
                    enabled: true,
                },
            },
            plotOptions: {
                bar: {
                    borderRadius: 0,
                    horizontal: true,
                    barHeight: '80%',
                    isFunnel: true,
                },


                legend: {
                    show: false,
                },
            },
            xaxis: {
                categories: [
                    'Binnengekomen',
                    'Beantwoord',
                    'Doorgezet',
                    'Factcheck uitgevoerd',
                ],
            },
        }
    }
}
